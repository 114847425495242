import axios from "axios";
import React, { useState, useEffect } from "react";
import {useNavigate, NavLink } from "react-router-dom";

import TopNav from "./TopNav";
import AsideNavBar from "./AsideNavBar";
import Footer from "./Footer";

function NewProcedure() {
  const navigate = useNavigate();
  const [formvalue, setFormvalue] = useState({
    uhidno: "",
    uhdate: "",
    billno: "",
    billdate: "",
    pname: "",
    pmob: "",
    page: "",
    psex: "",
    pcat:"",
    pidp: "",
    pidn: "",
    padd: "",
    pcity: "",
    pstate: "",    
    pcode: "",
    pdoc: "",
    premark: "",
    protype: "",
    srvtype: "",
    srvcode: "",
    pservcode: "",
    srvprice: "",
    srvqty: "",
    totcharge: "",
    disc: "",
    netamt: "",
    refamnt: "",
    pamnt: "",
    bamnt: "",   
  });

  const [message, setMessage] = useState("");

  const handleInput = (e) => {
    setFormvalue({ ...formvalue, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    //console.log(formvalue);
    const formData = {
      uhidno: formvalue.uhidno,
      uhdate: formvalue.uhdate,
      billno: formvalue.billno,
      billdate: formvalue.billdate,
      pname: formvalue.pname,
      pmob: formvalue.pmob,
      page: formvalue.page,
      psex: formvalue.psex,
      pcat: formvalue.pcat,
      pidp: formvalue.pidp,
      pidn: formvalue.pidn,
      padd: formvalue.padd,
      pcity: formvalue.pcity,
      pstate: formvalue.pstate,    
      pcode: formvalue.pcode,
      pdoc: formvalue.pdoc,
      premark: formvalue.premark,
      protype: formvalue.protype,
      srvtype: formvalue.srvtype,
      srvcode: formvalue.srvcode,
      pservcode: formvalue.pservcode,
      srvprice: formvalue.srvprice,
      srvqty: formvalue.srvqty,
      totcharge: formvalue.totcharge,
      disc: formvalue.disc,
      netamt: formvalue.netamt,
      refamnt: formvalue.refamnt,
      pamnt: formvalue.pamnt,
      bamnt: formvalue.bamnt,
    };

    console.log(formData);

    const res = await axios.post("http://localhost/coxidoc/api/procedure.php", formData);

    console.log(formData);

    if (res.data.success) {
      setMessage(res.data.success || "Data Successfully added");
      setTimeout(() => {
        navigate("/NewProcedure");
      }, 1000);   

    } else {
      // Handle other responses or errors
      // setMessage(res.data.error || "Unknown error occurred");
      setMessage(res.data)
    }
  };

  // Data fetching for the below table
  const [userData, setUserData] = useState([]);
  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = async () => {
    const reqData = await fetch("http://localhost/coxidoc/api/procedure.php");
    const resData = await reqData.json();
    console.log(resData);
    setUserData(resData);
  };

  return (
    <React.Fragment>
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row my-2">
              <div className="col-sm-6">
                <h1>New Procedure</h1>
              </div>

              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <NavLink to="/dashboard">Home</NavLink>
                  </li>
                  <li className="breadcrumb-item active">New Procedure</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <h5>UHID Details </h5>
                    <p className="text-warning">{message}</p>
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-md-3 mt-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="UHID Number"
                            name="uhidno"
                            value={formvalue.uhidno}
                            onChange={handleInput}
                          />
                        </div>
                        <div className="col-md-2 mt-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="UHID Date"
                            name="uhdate"
                            value={formvalue.uhdate}
                            onChange={handleInput}
                          />
                        </div>
                        <div className="col-md-2 mt-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Bill Number"
                            name="billno"
                            value={formvalue.billno}
                            onChange={handleInput}
                          />
                        </div>
                        <div className="col-md-3 mt-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Bill Date"
                            name="billdate"
                            value={formvalue.billdate}
                            onChange={handleInput}
                          />
                          <br></br>
                        </div>
                      </div>
                      <h5> Patient Details </h5>
                      <div className="row">
                        <div className="col-md-3 mt-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Patient Name"
                            name="pname"
                            value={formvalue.pname}
                            onChange={handleInput}
                          />
                        </div>
                        <div className="col-md-2 mt-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Mobile No"
                            name="pmob"
                            value={formvalue.pmob}
                            onChange={handleInput}
                          />
                        </div>
                        <div className="col-md-1 mt-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Age"
                            name="page"
                            value={formvalue.page}
                            onChange={handleInput}
                          />
                        </div>
                        <div className="col-md-1 mt-3">
                          <select
                            name="psex"
                            className="form-control"
                            value={formvalue.psex}
                            onChange={handleInput}
                          >
                            <option value="Not Selected">Sex</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Other">Other</option>
                          </select>
                        </div>
                        <div className="col-md-1 mt-3">
                          <select
                            name="pcat"
                            className="form-control"
                            value={formvalue.pcat}
                            onChange={handleInput}
                          >
                            <option value="Not Selected">Category</option>
                            <option value="Direct">Direct</option>
                            <option value="Referal">Referal</option>
                          </select>
                        </div>
                        <div className="col-md-2 mt-3">
                          <select
                            name="pidp"
                            className="form-control"
                            value={formvalue.pidp}
                            onChange={handleInput}
                          >
                            <option value="Not Selected">ID Proof</option>
                            <option value="Adhaar Card">Adhaar Card</option>
                            <option value="Pan Card">Pan Card</option>
                          </select>
                        </div>
                        <div className="col-md-2 mt-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="ID number"
                            name="pidn"
                            value={formvalue.pidn}
                            onChange={handleInput}
                          />
                        </div>

                        <div className="col-md-6 mt-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Patient Address"
                            name="padd"
                            value={formvalue.padd}
                            onChange={handleInput}
                          />
                        </div>
                        <div className="col-md-2 mt-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="City"
                            name="pcity"
                            value={formvalue.pcity}
                            onChange={handleInput}
                          />
                        </div>
                        <div className="col-md-2 mt-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="State"
                            name="pstate"
                            value={formvalue.pstate}
                            onChange={handleInput}
                          />
                        </div>
                        <div className="col-md-2 mt-3">
                          <input
                            required
                            type="text"
                            className="form-control"
                            placeholder="Pin"
                            name="pcode"
                            value={formvalue.pcode}
                            onChange={handleInput}
                          />
                        </div>
                        <div className="col-md-2 mt-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Registration Date"
                            name="prdate"
                            value={formvalue.prdate}
                            onChange={handleInput}
                          />
                        </div>
                        <div className="col-md-2 mt-3">
                          <select
                            name="pdoc"
                            className="form-control"
                            value={formvalue.pdoc}
                            onChange={handleInput}
                          >
                            <option value="">Choose Docter</option>
                            <option value="Dr. Vishal">Dr. Vishal</option>
                            <option value="Dr. Kavita">Dr. Kavita</option>
                            <option value="Dr. Amit Dahiya">
                              Dr. Amit Dahiya
                            </option>
                            <option value="Dr. Sandeep Verma">
                              Dr. Sandeep Verma
                            </option>
                            <option value="Anyone as per the availability">
                              Anyone as per the availability
                            </option>
                          </select>
                        </div>
                        <div className="col-md-10 mt-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Remark for Patient"
                            name="premark"
                            value={formvalue.premark}
                            onChange={handleInput}
                          />
                          <br></br>
                        </div>
                      </div>
                      <h5 className="mb-3">Procedure Type</h5>
                      <div className="row">
                        <div className="col-md-2 mt-3">
                          <select
                            name="protype"
                            className="form-control"
                            value={formvalue.protype}
                            onChange={handleInput}
                          >
                            <option value="">Choose Procedure</option>
                            <option value="Procedure 01">Procedure 01</option>
                            <option value="Procedure 02">Procedure 02</option>
                          </select>
                        </div>
                        {/* <div className="col-md-2 mt-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="OPD ID"
                            name="popdid"
                            value={formvalue.popdid}
                            onChange={handleInput}
                          />
                        </div> */}
                        <div className="col-md-2 mt-3">
                          <select
                            name="srvtype"
                            className="form-control"
                            value={formvalue.srvtype}
                            onChange={handleInput}
                          >
                            <option value="">Service Type</option>
                            <option value="Regular">Regular</option>
                            <option value="Monthly">Monthly</option>
                          </select>
                        </div>
                        <div className="col-md-2 mt-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Service Code"
                            name="srvcode"
                            value={formvalue.srvcode}
                            onChange={handleInput}
                          />
                        </div>
                        <div className="col-md-2 mt-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Service Price"
                            name="srvprice"
                            value={formvalue.srvprice}
                            onChange={handleInput}
                          />
                        </div>
                        <div className="col-md-2 mt-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Service Quantity"
                            name="srvqty"
                            value={formvalue.srvqty}
                            onChange={handleInput}
                          />
                        </div>
                        <div className="col-md-2 mt-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Total Charge"
                            name="totcharge"
                            value={formvalue.totcharge}
                            onChange={handleInput}
                          />
                        </div>
                        <div className="col-md-2 mt-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Discount"
                            name="disc"
                            value={formvalue.disc}
                            onChange={handleInput}
                          />
                        </div>
                        <div className="col-md-2 mt-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Net Amount"
                            name="netamt"
                            value={formvalue.netamt}
                            onChange={handleInput}
                          />
                        </div>
                        <div className="col-md-2 mt-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Refund Amount"
                            name="refamnt"
                            value={formvalue.refamnt}
                            onChange={handleInput}
                          />
                        </div>
                        <div className="col-md-2 mt-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Paid Amount"
                            name="pamnt"
                            value={formvalue.pamnt}
                            onChange={handleInput}
                          />
                        </div>
                        <div className="col-md-2 mt-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Balance Amount"
                            name="bamnt"
                            value={formvalue.bamnt}
                            onChange={handleInput}
                          />
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-md-2">
                          <button
                            name="submit"
                            className="btn btn-block btn-success"
                          >
                            Add it
                          </button>
                        </div>
                        <div className="col-md-1">
                          <button
                            name="print"
                            className="btn btn-block btn-warning"
                          >
                            Print
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="col-12">
                  <div class="card">
                    <div class="card-header">
                      <h3 class="card-title">Procedure List</h3>                      
                    </div>
                    <div class="card-body">
                      <table
                        id="example1"
                        class="table table-bordered table-striped"
                      >
                        <thead>
                          <tr>
                            <th>Sr. No.</th>
                            <th>Services</th>
                            <th>Service Code</th>
                            <th>Price</th>
                            <th>Qty</th>
                            <th>Total Amount</th>
                            <th>Disc.</th>
                            <th>Net Amnt</th>
                            <th>Ref Amnt</th>
                            <th>Paid</th>
                            <th>Bal Amnt</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                        {userData.map((uData, index) => (
                          <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{uData.srvtype}</td>
                          <td>{uData.srvcode}</td>
                          <td>{uData.srvprice}</td>
                          <td>{uData.srvqty}</td>
                            <td>{uData.totcharge}</td>
                            <td>{uData.netamt}</td>
                            <td>{uData.disc}</td>
                            <td>{uData.refamnt}</td>
                            <td>{uData.pamnt}</td>
                            <td>{uData.bamnt}</td>
                            <td>
                              <NavLink className="btn btn-warning btn-sm">
                                Edit
                              </NavLink>
                              <button className="btn btn-danger btn-sm mx-2">
                                Delete
                              </button>                              
                            </td>
                          </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default NewProcedure;
