import React from "react";

const Footer = () => {
    return (
        <>
            <div class="main-footer">
                Copyright &copy; <script>document.write(new Date().getFullYear());</script> <a href="https://codexwindow.com" rel="noreferrer" target="_blank">CodexWindow Tech Solutions</a>.
                All rights reserved.
                <div class="float-right d-none d-sm-inline-block">
                    <b>Version</b> 1.1
                </div>
            </div>
        </>
    )
}


export default Footer;


