import React, {  } from "react";
import ContentDashboard from "./ContentDashboard";

 
export default function Dashboard() {
    return(
        <>
        <div className="wrapper">
                <ContentDashboard />                
            </div>
        </>
    )
}