import axios from "axios";
import React, { useState } from "react";
import { useNavigate, NavLink } from "react-router-dom";


function Newopd() {
  
  const navigate = useNavigate();
  
  const [formvalue, setFormvalue] = useState({pname: "", pmob: "", page: "", psex: "", pcat: "", pidp: "", pidn: "", padd: "", pcity: "", pstate: "", prdate: "", premark: "", prtoken: "", psrv: "", pdoc: "", pserv: "", pservcode: "", psrate: "", psdisc: "", psqty: "", psamp: "", pstamt: "",
    psmtdue: "", pservpmode: "", pspdate: "", psamtinword: "",});
  
  const [message, setMessage] = useState("");

  const handleInput = (e) => {
    setFormvalue({ ...formvalue, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    //console.log(formvalue);    
    const formData = {
      pname: formvalue.pname,
      pmob: formvalue.pmob,
      page: formvalue.page,
      psex: formvalue.psex,
      pcat: formvalue.pcat,
      pidp: formvalue.pidp,
      pidn: formvalue.pidn,
      padd: formvalue.padd,
      pcity: formvalue.pcity,
      pstate: formvalue.pstate, 
      pcode: formvalue.pcode,      
      prdate: formvalue.prdate,
      premark: formvalue.premark,
      prtoken: formvalue.prtoken,   
      psrv: formvalue.psrv,
      pdoc: formvalue.pdoc,
      pserv:formvalue.pserv,
      pservcode: formvalue.pservcode,
      psrate: formvalue.psrate,
      psdisc: formvalue.psdisc,
      psqty: formvalue.psqty,
      psamp: formvalue.psamp,
      pstamt: formvalue.pstamt,
      psmtdue: formvalue.psmtdue,
      pservpmode: formvalue.pservpmode,
      pspdate: formvalue.pspdate,
      psamtinword: formvalue.psamtinword}

    console.log(formData);

    const res = await axios.post("http://localhost/coxidoc/api/opd.php", formData);

    console.log(res.data);

    if (res.data.success) {
      setMessage(res.data.success || "Data Successfully added");
      setTimeout(() => {
        navigate("/OpdData");
      }, 1000);     
    }else {
        // Handle other responses or errors
        setMessage(res.data.error || "Unknown error occurred"); 
    } 
  } 
  return (
    <React.Fragment>
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row my-2">
              <div className="col-sm-6">
                <h1>New OPD</h1>
              </div>

              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <NavLink to="/dashboard">Home</NavLink>
                  </li>
                  <li className="breadcrumb-item active">New OPD</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <h5>Patient Details </h5>
                    <p className="text-warning">{message}</p>
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-md-3 mt-3">
                          <input 
                            type="text"
                            className="form-control"
                            placeholder="Patient Name"                           
                            name="pname"
                            value={formvalue.pname}
                            onChange={handleInput}
                          />
                        </div>
                        <div className="col-md-2 mt-3">
                          <input 
                            type="text"
                            className="form-control"
                            placeholder="Mobile No"
                            name="pmob"
                            value={formvalue.pmob}
                            onChange={handleInput}
                          />
                        </div>
                        <div className="col-md-1 mt-3">
                          <input 
                            type="text"
                            className="form-control"
                            placeholder="Age"
                            name="page"
                            value={formvalue.page}
                            onChange={handleInput}
                          />
                        </div>
                        <div className="col-md-1 mt-3">
                          <select
                            name="psex"
                            className="form-control"
                            value={formvalue.psex}
                            onChange={handleInput}>
                            <option value="Not Selected">Sex</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Other">Other</option>
                          </select>
                        </div>  
                        <div className="col-md-1 mt-3">
                          <select
                            name="pcat"
                            className="form-control"
                            value={formvalue.pcat}
                            onChange={handleInput}>
                            <option value="Not Selected">Category</option>
                            <option value="Direct">Direct</option>
                            <option value="Referal">Referal</option>
                          </select>
                        </div>
                        <div className="col-md-2 mt-3">
                          <select
                            name="pidp"
                            className="form-control"
                            value={formvalue.pidp}
                            onChange={handleInput}>
                            <option value="Not Selected">ID Proof</option>
                            <option value="Adhaar Card">Adhaar Card</option>
                            <option value="Pan Card">Pan Card</option>                            
                          </select>
                        </div> 
                        <div className="col-md-2 mt-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="ID number"
                            name="pidn"
                            value={formvalue.pidn}
                            onChange={handleInput}
                          />
                        </div>                      
                        
                        
                        <div className="col-md-6 mt-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Patient Address"
                            name="padd"
                            value={formvalue.padd}
                            onChange={handleInput}
                          />
                        </div>
                        <div className="col-md-2 mt-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="City"
                            name="pcity"
                            value={formvalue.pcity}
                            onChange={handleInput}
                          />
                        </div>
                        <div className="col-md-2 mt-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="State"
                            name="pstate"
                            value={formvalue.pstate}
                            onChange={handleInput}
                          />
                        </div>
                        <div className="col-md-2 mt-3">
                          <input required
                            type="text"
                            className="form-control"
                            placeholder="Pin"
                            name="pcode"
                            value={formvalue.pcode}
                            onChange={handleInput}
                          />
                        </div>
                        <div className="col-md-2 mt-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Registration Date"
                            name="prdate"
                            value={formvalue.prdate}
                            onChange={handleInput}
                          />
                        </div> 
                        <div className="col-md-10 mt-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Remark for Patient"
                            name="premark"
                            value={formvalue.premark}
                            onChange={handleInput}
                          />
                          <br></br>
                        </div>                        
                    </div>
                    <h5 className="mb-3">Service Details</h5>
                    <div className="row">
                    <div className="col-md-2 mt-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Referal Token"
                            name="prtoken"
                            value={formvalue.prtoken}
                            onChange={handleInput}
                          />
                        </div>
                    {/* <div className="col-md-2 mt-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="OPD ID"
                            name="popdid"
                            value={formvalue.popdid}
                            onChange={handleInput}
                          />
                        </div> */}
                        <div className="col-md-2 mt-3">
                          <select
                            name="psrv"
                            className="form-control"
                            value={formvalue.psrv}
                            onChange={handleInput}>
                            <option value="">Service Name</option>
                            <option value="Dentist">Dentist</option>
                            <option value="EyeCare">EyeCare</option>                            
                          </select>
                        </div>
                        <div className="col-md-2 mt-3">
                          <select
                            name="pdoc"
                            className="form-control"
                            value={formvalue.pdoc}
                            onChange={handleInput}>
                            <option value="">Choose Docter</option>
                            <option value="Dr. Vishal">Dr. Vishal</option>
                            <option value="Dr. Kavita">Dr. Kavita</option>
                            <option value="Dr. Amit Dahiya">Dr. Amit Dahiya</option>
                            <option value="Dr. Sandeep Verma">Dr. Sandeep Verma</option>  
                            <option value="Anyone as per the availability">Anyone as per the availability</option>                          
                          </select>
                        </div>
                        <div className="col-md-2 mt-3">
                          <select
                            name="pserv"
                            className="form-control"
                            value={formvalue.pserv}
                            onChange={handleInput}>
                            <option value="">Service Type</option>
                            <option value="Regular">Regular</option>
                            <option value="Monthly">Monthly</option>                            
                          </select>
                        </div>
                        <div className="col-md-2 mt-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Service Code"
                            name="pservcode"
                            value={formvalue.pservcode}
                            onChange={handleInput}
                          /> <br></br>
                        </div>
                        </div>
                        <div className="row">
                        <div className="col-md-12"><h5 className="mb-3">Payment Details</h5></div>
                        
                        <div className="col-md-2 mt-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="@Rate"
                            name="psrate"
                            value={formvalue.psrate}
                            onChange={handleInput}
                          />
                        </div>
                        <div className="col-md-2 mt-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Discount"
                            name="psdisc"
                            value={formvalue.psdisc}
                            onChange={handleInput}
                          />
                        </div>  
                        <div className="col-md-2 mt-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="qty"
                            name="psqty"
                            value={formvalue.psqty}
                            onChange={handleInput}
                          />
                        </div>
                        <div className="col-md-2 mt-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Amount Paid"
                            name="psamp"
                            value={formvalue.psamp}
                            onChange={handleInput}
                          />
                        </div>  
                        <div className="col-md-2 mt-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Total Paid"
                            name="pstamt"
                            value={formvalue.pstamt}
                            onChange={handleInput}
                          />
                        </div>  
                        <div className="col-md-2 mt-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Amount Due"
                            name="psmtdue"
                            value={formvalue.psmtdue}
                            onChange={handleInput}
                          />
                        </div>                         
                        <div className="col-md-3 mt-3">
                          <select
                            name="pservpmode"
                            className="form-control"
                            value={formvalue.pservpmode}
                            onChange={handleInput}>
                            <option value="">Payment Mode</option>
                            <option value="Chaque">Chaque</option>
                            <option value="Cash">Cash</option>
                            <option value="UPI">UPI</option>
                            <option value="Credit Card">Credit Card</option>                                                        
                          </select>
                        </div> 
                        <div className="col-md-3 mt-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Payment Date"
                            name="pspdate"
                            value={formvalue.pspdate}
                            onChange={handleInput}
                          />
                        </div>
                        <div className="col-md-6 mt-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="In words"
                            name="psamtinword"
                            value={formvalue.psamtinword}
                            onChange={handleInput}
                          />
                        </div>                                        
                        </div>
                        <div className="row mt-4">
                        <div className="col-md-2">
                          <button name="submit" className="btn btn-block btn-success">
                            Create new OPD
                          </button>
                        </div>
                        <div className="col-md-1">
                          <button name="print" className="btn btn-block btn-warning">
                            Print
                          </button>
                        </div>
                      </div>                      
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Newopd;
