import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams, NavLink } from "react-router-dom";

function EditAppointment() {
  const navigate = useNavigate();
  const { id } = useParams();
  //console.log(id)
  const [formvalue, setFormvalue] = useState({
    pname: '',
    pmob: '',
    padd: '',
    padate: '',
    patime: '',
    padoc: '',
    pmsg: '',
    pcat: '',
    pstatus: '',
  });
  const [message, setMessage] = useState("");

  const handleInput = (e) => {
    setFormvalue({ ...formvalue, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    const userRowdata = async () => {
      const getUserdata = await fetch("http://localhost/coxidoc/api/appointment.php/" + id);
      const reuserdata = await getUserdata.json();
      //console.log(reuserdata);
      setFormvalue(reuserdata);
    };
    userRowdata();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    //console.log(formvalue);
    const formData = {
        id: id,
        pname: formvalue.pname,
        pmob: formvalue.pmob,
        padd: formvalue.padd,
        padate: formvalue.padate,
        patime: formvalue.patime,
        padoc: formvalue.padoc,
        pmsg: formvalue.pmsg,
        pcat: formvalue.pcat,
        pstatus: formvalue.pstatus,
    };
    const res = await axios.put("http://localhost/coxidoc/api/appointment.php",formData);

    if (res.data.success) {
      setMessage(res.data.success);
      setTimeout(() => {
        navigate("/AppoinmentData");
      }, 2000);
    }else {
      // Handle other responses or errors
      setMessage(res.data.error || "Unknown error occurred"); 
  } 
  };
  
  return (
    <React.Fragment>
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row my-2">
              <div className="col-sm-6">
                <h1>Update the Appointment details</h1>
              </div>

              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <NavLink to="/dashboard">Home</NavLink>
                  </li>
                  <li className="breadcrumb-item">
                    <NavLink to="/appoinmentdata">Appointment Data</NavLink>
                  </li>                  
                  <li className="breadcrumb-item active">Edit Appointment</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <h5>Personal Details</h5>
                    <p className="text-warning">{message}</p>
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-md-4">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Patient Name"                           
                            name="pname"
                            value={formvalue.pname}
                            onChange={handleInput}
                          />
                        </div>
                        <div className="col-md-2">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Mobile No"
                            name="pmob"
                            value={formvalue.pmob}
                            onChange={handleInput}
                          />
                        </div>
                        <div className="col-md-6">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Patient Address"
                            name="padd"
                            value={formvalue.padd}
                            onChange={handleInput}
                          />
                        </div>
                    </div>
                    <h5 className="my-3">Appointment Details</h5>
                    <div className="row">
                    <div className="col-md-2">
                          <select
                            name="padoc"
                            className="form-control"
                            value={formvalue.padoc}
                            onChange={handleInput}>
                            <option value="Not Seleted">Choose Docter</option>
                            <option value="Dr. Vishal">Dr. Vishal</option>
                            <option value="Dr. Kavita">Dr. Kavita</option>
                            <option value="Dr. Amit Dahiya">Dr. Amit Dahiya</option>
                            <option value="Dr. Sandeep Verma">Dr. Sandeep Verma</option>  
                            <option value="Anyone as per the availability">Anyone as per the availability</option>                          
                          </select>
                        </div>
                        <div className="col-md-2">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Appointment Date"
                            name="padate"
                            value={formvalue.padate}
                            onChange={handleInput}
                          />
                        </div>
                        <div className="col-md-2">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Appointment Time"
                            name="patime"
                            value={formvalue.patime}
                            onChange={handleInput}
                          />
                        </div>
                        <div className="col-md-6">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Message"
                            name="pmsg"
                            value={formvalue.pmsg}
                            onChange={handleInput}
                          />
                        </div>
                        </div>
                        <div className="row my-3">
                        <div className="col-md-2">
                          <select
                            name="pcat"
                            className="form-control"
                            value={formvalue.pcat}
                            onChange={handleInput}>
                            <option value="">Category</option>
                            <option value="Direct">Direct</option>
                            <option value="Referal">Referal</option>
                          </select>
                        </div>

                        <div className="col-md-2">
                          <select
                            name="pstatus"
                            className="form-control"
                            value={formvalue.pstatus}
                            onChange={handleInput}>
                            <option value="Not Selected">Status</option>
                            <option value="Active">Active</option>
                            <option value="InActive">InActive</option>
                          </select>
                        </div>
                        </div>
                        <div className="row mt-4">
                        <div className="col-md-2">
                          <button name="submit" className="btn btn-success">
                            Update
                          </button>
                        </div>
                      </div>                      
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default EditAppointment;
