import React from "react";
import {Routes, Route, useLocation } from "react-router-dom";

import TopNav from "./Comp/TopNav";
import AsideNavBar from "./Comp/AsideNavBar";
import Footer from "./Comp/Footer";

import Protected from "./Comp/Protected";
import Dashboard from "./Comp/Dashboard";
import Login from "./Comp/Login";
import NewAppoinment from "./Comp/NewAppoinment";
import AppoinmentData from "./Comp/AppoinmentData";
import Newopd from "./Comp/Newopd";
import OpdData from "./Comp/OpdData";
import Editopd from "./Comp/Editopd";
import PatientData from "./Comp/PatientData";
import NewProcedure from "./Comp/NewProcedure";
import Invoice from "./Comp/Invoice";
import EditAppointment from "./Comp/EditAppointment";
import ErrorPage from "./Comp/ErrorPage";

function App() {
const loginpath = useLocation(); 
const isLoginPage = loginpath.pathname;
//console.log(isLoginPage);
return (
    <>
      {isLoginPage =="/" ? (
        <Routes>
          <Route path="/" element={<Login />} />
        </Routes>
      ) : (
        <>
          <TopNav />
          <AsideNavBar />
          <Routes>
            <Route
              path="/dashboard"
              element={<Protected Component={Dashboard} />}
            />
            <Route
              path="/NewAppoinment"
              element={<Protected Component={NewAppoinment} />}
            />
            <Route
              path="/AppoinmentData"
              element={<Protected Component={AppoinmentData} />}
            />
            <Route
              path="/EditAppointment"
              element={<Protected Component={EditAppointment} />}
            />
            <Route path="/Newopd" element={<Protected Component={Newopd} />} />
            <Route path="/OpdData" element={<Protected Component={OpdData} />} />
            <Route
              path="/Editopd"
              element={<Protected Component={Editopd} />}
            />
            <Route
              path="/NewProcedure"
              element={<Protected Component={NewProcedure} />}
            />
            <Route
              path="/PatientData"
              element={<Protected Component={PatientData} />}
            />
            <Route path="/Invoice" element={<Protected Component={Invoice} />} />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
          <Footer />
        </>
      )}
    </>
  );
}

export default App;
