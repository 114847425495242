import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";

function OpdData() {
  const [userData, setUserData] = useState([]);
  const [message, setMessage] = useState("");

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = async () => {
    const reqData = await fetch("http://localhost/coxidoc/api/opd.php");
    const resData = await reqData.json();
    console.log(resData);
    setUserData(resData);
  };

  const handleDelete = async (opdid) => {
    const res = await axios.delete(
      "http://localhost/coxidoc/api/opd.php/" + opdid
    );
    setMessage(res.data.success);
    getUserData();
  };
  return (
    <React.Fragment>
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row my-2">
              <div className="col-sm-6">
                <h1>All OPD Data</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <NavLink to="/dashboard">Home</NavLink>
                  </li>
                  <li className="breadcrumb-item active">All OPD Data</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div class="card">
                  <div class="card-header">
                    <h3 class="card-title">OPD list</h3>
                    <p className="text-danger">{message}</p>
                  </div>
                  <div class="card-body">
                    <table
                      id="example1"
                      class="table table-bordered table-striped"
                    >
                      <thead>
                        <tr>
                          <th>Sr. No.</th>
                          <th>Patient Name</th>
                          <th>Mobile</th>
                          {/* <th>Address</th> */}
                          <th>Age</th>
                          <th>Sex</th>
                          <th>Category</th>
                          <th>ID Proof</th>
                          <th>ID number</th>
                          <th>Address</th>
                          <th>City</th>
                          <th>State</th>
                          <th>PIN</th>
                          <th>Reg Date</th>
                          <th>Remark</th>
                          <th>Token no</th>
                          <th>Sevice Name</th>
                          <th>Doctor</th>
                          <th>Service Type</th>
                          <th>Service Code</th>
                          <th>@Rate</th>
                          <th>Discount</th>
                          <th>Qty</th>
                          <th>Amount paid</th>
                          <th>Total Paid</th>
                          <th>Amount Due</th>
                          <th>Payment mode</th>
                          <th>Payment Date</th>
                          <th>In Words</th>
                        </tr>
                      </thead>
                      <tbody>
                        {userData.map((uData, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{uData.pname}</td>
                            <td>{uData.pmob}</td>
                            <td>{uData.page}</td>
                            <td>{uData.psex}</td>
                            <td>{uData.pcat}</td>
                            <td>{uData.pidp}</td>
                            <td>{uData.pidn}</td>
                            <td>{uData.padd}</td>
                            <td>{uData.pcity}</td>
                            <td>{uData.pstate}</td>
                            <td>{uData.pcode}</td>
                            <td>{uData.prdate}</td>
                            <td>{uData.premark}</td>
                            <td>{uData.prtoken}</td>
                            <td>{uData.psrv}</td>
                            <td>{uData.pdoc}</td>
                            <td>{uData.pserv}</td>
                            <td>{uData.pservcode}</td>
                            <td>{uData.psrate}</td>
                            <td>{uData.psdisc}</td>
                            <td>{uData.psqty}</td>
                            <td>{uData.psamp}</td>
                            <td>{uData.pstamt}</td>
                            <td>{uData.psmtdue}</td>
                            <td>{uData.pservpmode}</td>
                            <td>{uData.pspdate}</td>
                            <td>{uData.psamtinword}</td>
                            
                            {/* <td>
                          {uData.status === "1" ? "Active" : "InActive"}
                        </td> */}
                            <td>
                              <NavLink
                                to={"/editopd/" + uData.opdid}
                                className="btn btn-warning btn-sm mx-2"
                              >
                                Edit
                              </NavLink>
                              <NavLink
                                to={"/newprocedure/" + uData.opdid}
                                className="btn btn-success btn-sm mx-2"
                              >
                                Add Procedure
                              </NavLink>
                              <button
                                className="btn btn-danger btn-sm"
                                onClick={() => handleDelete(uData.opdid)}
                              >
                                Delete
                              </button>
                              
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default OpdData;
