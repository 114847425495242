import axios from "axios";
import React, { useState } from "react";
import { useNavigate, NavLink } from "react-router-dom";

function NewAppoinment() {
  
  const navigate = useNavigate();
  
  const [formvalue, setFormvalue] = useState({
    pname: "",
    pmob: "",
    padd: "",
    padate: "",
    patime: "",
    padoc: "",
    pmsg: "",
    pcat: "",
    pstatus: "",
  });
  
  const [message, setMessage] = useState("");

  const handleInput = (e) => {
    setFormvalue({ ...formvalue, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    //console.log(formvalue);
    const formData = {
      pname: formvalue.pname,
      pmob: formvalue.pmob,
      padd: formvalue.padd,
      padate: formvalue.padate,
      patime: formvalue.patime,
      padoc: formvalue.padoc,
      pmsg: formvalue.pmsg,
      pcat: formvalue.pcat,
      pstatus: formvalue.pstatus,
    };
    const res = await axios.post("http://localhost/coxidoc/api/appointment.php", formData);
    //const res = await axios.post("https://portal.32pearlsdentalcare.com/server/appointment.php", formData);

    if (res.data.success) {
      setMessage(res.data.success);
      setTimeout(() => {
        navigate("/AppoinmentData");
      }, 1000);
    }
  }
  return (
    <React.Fragment>
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row my-2">
              <div className="col-sm-6">
                <h1>New Appointment</h1>
              </div>

              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <NavLink to="/dashboard">Home</NavLink>
                  </li>
                  <li className="breadcrumb-item active">New Appoinmnet</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <h5>Personal Details</h5>
                    
                    <p className="text-danger">{message}</p>
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-md-4">
                          <input required
                            type="text"
                            className="form-control"
                            placeholder="Patient Name"                           
                            name="pname"
                            value={formvalue.pname}
                            onChange={handleInput}
                          />
                        </div>
                        <div className="col-md-2">
                          <input required
                            type="text"
                            className="form-control"
                            placeholder="Mobile No"
                            name="pmob"
                            value={formvalue.pmob}
                            onChange={handleInput}
                          />
                        </div>
                        <div className="col-md-6">
                          <input required
                            type="text"
                            className="form-control"
                            placeholder="Patient Address"
                            name="padd"
                            value={formvalue.padd}
                            onChange={handleInput}
                          />
                        </div>
                    </div>
                    <h5 className="my-3">Appointment Details</h5>
                    <div className="row">
                    <div className="col-md-2">
                          <select required
                            name="padoc"
                            className="form-control"
                            value={formvalue.padoc}
                            onChange={handleInput}>
                            <option value="">Choose Docter</option>
                            <option value="Dr. Vishal">Dr. Vishal</option>
                            <option value="Dr. Kavita">Dr. Kavita</option>
                            <option value="Dr. Amit Dahiya">Dr. Amit Dahiya</option>
                            <option value="Dr. Sandeep Verma">Dr. Sandeep Verma</option>  
                            <option value="Anyone as per the availability">Anyone as per the availability</option>                          
                          </select>
                        </div>
                        <div className="col-md-2">
                          <input required
                            type="date"
                            id="dateInput"
                            className="form-control"
                            placeholder="Appointment Date"
                            name="padate"
                            value={formvalue.padate}
                            onChange={handleInput}
                          />                           
                        </div>
                        <div className="col-md-2">
                          <input required
                            type="time"
                            className="form-control"
                            placeholder="Appointment Time"
                            name="patime"
                            value={formvalue.patime}
                            onChange={handleInput}
                          />
                        </div>
                        <div className="col-md-6">
                          <input required
                            type="text"
                            className="form-control"
                            placeholder="Message"
                            name="pmsg"
                            value={formvalue.pmsg}
                            onChange={handleInput}
                          />
                        </div>
                        </div>
                        <div className="row my-3">
                        <div className="col-md-2">
                          <select required
                            name="pcat"
                            className="form-control"
                            value={formvalue.pcat}
                            onChange={handleInput}>
                            <option value="">Category</option>
                            <option value="Direct">Direct</option>
                            <option value="Referal">Referal</option>
                          </select>
                        </div>

                        <div className="col-md-2">
                          <select required
                            name="pstatus"
                            className="form-control"
                            value={formvalue.pstatus}
                            onChange={handleInput}>
                            <option value="">Status</option>
                            <option value="Active">Active</option>
                            <option value="InActive">InActive</option>
                          </select>
                        </div>
                        </div>
                        <div className="row mt-4">
                        <div className="col-md-2">
                          <button name="submit" className="btn btn-success">
                            Submit
                          </button>
                        </div>
                      </div>                      
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
s    </React.Fragment>
  );
}

export default NewAppoinment;
