import React from "react";
import { NavLink } from "react-router-dom";

const ContentDashboard = () => {
    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Dashboard</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><NavLink to="/dashboard">Home</NavLink></li>
                                <li className="breadcrumb-item active">Dashboard</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <section className="content">
                <div className="container-fluid">

                    <div className="row">
                        <div className="col-lg-3 col-6">

                            <div className="small-box bg-info">
                                <div className="inner">
                                    <h3>150</h3>

                                    <p>Today's Appointment</p>
                                </div>
                                <div className="icon">
                                    <i className="ion ion-bag"></i>
                                </div>
                                <NavLink to="/" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></NavLink>
                            </div>
                        </div>

                        <div className="col-lg-3 col-6">

                            <div className="small-box bg-success">
                                <div className="inner">
                                    <h3>53<sup>%</sup></h3>

                                    <p>Today's OPD</p>
                                </div>
                                <div className="icon">
                                    <i className="ion ion-stats-bars"></i>
                                </div>
                                <NavLink to="/" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></NavLink>
                            </div>
                        </div>

                        <div className="col-lg-3 col-6">

                            <div className="small-box bg-warning">
                                <div className="inner">
                                    <h3>44</h3>

                                    <p>Today's Procedure</p>
                                </div>
                                <div className="icon">
                                    <i className="ion ion-person-add"></i>
                                </div>
                                <NavLink to="/" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></NavLink>
                            </div>
                        </div>

                        <div className="col-lg-3 col-6">

                            <div className="small-box bg-danger">
                                <div className="inner">
                                    <h3>65</h3>

                                    <p>Today's Total Visitors</p>
                                </div>
                                <div className="icon">
                                    <i className="ion ion-pie-graph"></i>
                                </div>
                                <NavLink to="/" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></NavLink>
                            </div>
                        </div>

                    </div>


                </div>
            </section>

        </div>
    )
}


export default ContentDashboard;


