import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";

function UserList() {
  const [userData, setUserData] = useState([]);
  const [message, setMessage] = useState("");

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = async () => {
    const reqData = await fetch("http://localhost/coxidoc/api/appointment.php");
    const resData = await reqData.json();
    console.log(resData);
    setUserData(resData);
  };

  const handleDelete = async (id) => {
    const res = await axios.delete(
      "http://localhost/coxidoc/api/appointment.php/" + id
    );
    setMessage(res.data.success);
    getUserData();
  };
  return (
    <React.Fragment>
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row my-2">
              <div className="col-sm-6">
                <h1>All Appointments</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <NavLink to="/dashboard">Home</NavLink>
                  </li>
                  <li className="breadcrumb-item active">All Appoinmnets</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div class="card">
                  <div class="card-header">
                    <h3 class="card-title">Appointment List</h3>
                    <p className="text-danger">{message}</p>
                  </div>
                  <div class="card-body">
                    <table
                      id="example1"
                      class="table table-bordered table-striped"
                    >
                      <thead>
                        <tr>
                          <th>Sr. No.</th>
                          <th>Patient Name</th>
                          <th>Mobile</th>
                          {/* <th>Address</th> */}
                          <th>Date</th>
                          <th>Time</th>
                          <th>Doctor</th>
                          <th>Message</th>
                          <th>Category</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {userData.map((uData, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{uData.pname}</td>
                            <td>{uData.pmob}</td>
                            {/* <td>{uData.padd}</td> */}
                            <td>{uData.pdate}</td>
                            <td>{uData.patime}</td>
                            <td>{uData.pdoc}</td>
                            <td>{uData.pmsg}</td>
                            <td>{uData.pcat}</td>
                            <td>{uData.pstatus}</td>

                            {/* <td>
                          {uData.status === "1" ? "Active" : "InActive"}
                        </td> */}
                            <td>
                              <NavLink
                                to={"/EditAppointment/" + uData.id}
                                className="btn btn-warning btn-sm mx-2"
                              >
                                Edit
                              </NavLink>
                              
                              <NavLink
                                to={"/Newopd"}
                                className="btn btn-success btn-sm mx-2"
                              >
                                New OPD
                              </NavLink>
                              <button
                                className="btn btn-danger btn-sm"
                                onClick={() => handleDelete(uData.id)}
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default UserList;
