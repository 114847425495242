import React from "react";
import { NavLink, useNavigate } from "react-router-dom";

const AsideNavBar = () => {
  const naviget = useNavigate();
  function logoutSubmit(){
      localStorage.setItem("login", "");
      localStorage.setItem("loginStatus", "Logged out successfully!")
      naviget("/");    
  }
  const user = localStorage.getItem('user');
  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4">
      <NavLink exact to="dashboard.html" className="brand-link">
        <img
          src="dist/img/coxi-apanel.png"
          alt="AdminLTE Logo"
          className="brand-image img-circle elevation-3"
        />
        <span className="brand-text font-weight-light">
          <b>Hi, {user}</b>          
        </span>       
      </NavLink>

      <div className="sidebar">
        <div className="form-inline my-3">
          <div className="input-group" data-widget="sidebar-search">
            <input
              className="form-control form-control-sidebar"
              type="search"
              placeholder="Search"
              aria-label="Search"
            />
            <div className="input-group-append">
              <button className="btn btn-sidebar">
                <i className="fas fa-search fa-fw"></i>
              </button>
            </div>
          </div>
        </div>

        <nav className="mt-2">
          <ul
            className="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            <li className="nav-item">
              <NavLink exact to="/dashboard" className="nav-link">
                <i className="nav-icon fas fa-tachometer-alt"></i>
                <p>Dashboard</p>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink exact to="/newappoinment" className="nav-link">
                <i className="nav-icon fas fa-edit"></i>
                <p>
                  Appointment
                  <span className="right badge badge-danger">New</span>
                </p>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink exact to="/appoinmentdata" className="nav-link">
                <i className="nav-icon fas fa-th"></i>
                <p>Appointment Data</p>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink exact to="/newopd" className="nav-link">
                <i className="nav-icon fas fa-edit"></i>
                <p>
                  OPD
                  <span className="right badge badge-info">New</span>
                </p>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink exact to="/opddata" className="nav-link">
                <i className="nav-icon fas fa-th"></i>
                <p>OPD Data</p>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink exact to="/newprocedure" className="nav-link">
                <i className="nav-icon fas fa-edit"></i>
                <p>
                  Procedure
                  <span className="right badge badge-info">New</span>
                </p>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink exact to="/patientdata" className="nav-link">
                <i className="nav-icon fas fa-th"></i>
                <p>Patient data</p>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink exact to="/invoice" className="nav-link">
                <i className="far fa-circle nav-icon"></i>
                <p>Invoice</p>
              </NavLink>
            </li>
            <button className="btn btn-outline-primary" type="submit" onClick={logoutSubmit}>
                Logout
              </button>
          </ul>
        </nav>
      </div>
    </aside>
  );
};

export default AsideNavBar;
