import React from "react";

const PatientData = () => {
return(
    <>
    <h1>PatientData Page</h1>
    </>
)
}

export default PatientData;